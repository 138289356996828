import React, { Component, useRef } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import Slider from 'react-slick';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  ImageCarousel,
  SearchMap,
  ListingCard,
  IconSpinner,
  ExternalLink,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { ReactComponent as Share } from '../../assets/shareicon.svg.svg';
import { ReactComponent as Pin } from '../../assets/location.svg';
// import { ReactComponent as Football } from '../../assets/Football.svg';
import { ReactComponent as ChangingRoom } from '../../assets/icons/ChangingRoom.svg';
import { ReactComponent as Bathroom } from '../../assets/icons/Bathroom.svg';
import { ReactComponent as Disabled } from '../../assets/icons/Disabled.svg';
import { ReactComponent as EquipmentHiring } from '../../assets/icons/EquipmentHiring.svg';
import { ReactComponent as FirstAidKit } from '../../assets/icons/FirstAidKit.svg';
import { ReactComponent as FloodLight } from '../../assets/icons/FloodLight.svg';
import { ReactComponent as FreeParking } from '../../assets/icons/FreeParking.svg';
import { ReactComponent as Lockers } from '../../assets/icons/Lockers.svg';
import { ReactComponent as PaidParking } from '../../assets/icons/PaidParking.svg';
import { ReactComponent as Restaurant } from '../../assets/icons/Restaurant.svg';
import { ReactComponent as Showers } from '../../assets/icons/Showers.svg';
import { ReactComponent as WiFi } from '../../assets/icons/WiFi.svg';
import { ReactComponent as HeartSvg } from '../../assets/icons/Heart.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';

import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineRight,
  AiOutlineStar,
  AiOutlineLeft,
} from 'react-icons/ai';
import { locationBounds } from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import classNames from 'classnames';
import moment from 'moment';
import {
  FacebookShareButton,
  WhatsappShareButton,
  RedditShareButton,
  TwitterShareButton,
  TelegramShareButton,
  EmailShareButton,
  LinkedinShareButton,
  LineShareButton,
  PinterestShareButton,
  WhatsappIcon,
  RedditIcon,
  TelegramIcon,
  EmailIcon,
  LineIcon,
  PinterestIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';
import { withViewport } from '../../util/contextHelpers.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const irelandCords = { lat: 53.1423672, lng: -7.692053599999999 };
const { UUID, LatLng } = sdkTypes;
const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
  '(max-width: 767px) 100vw',
  `(max-width: 1023px) ${panelMediumWidth}vw`,
  `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
  `${panelLargeWidth / 3}vw`,
].join(', ');
const GENERAL_INFO = 'generalInfo';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};
const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};
const amenities = [
  { key: 'ChangingRoom', label: 'Changing Room', icon: <ChangingRoom /> },
  { key: 'Disabled', label: 'Disabled Access', icon: <Disabled /> },
  { key: 'FreeParking', label: 'Free Parking', icon: <FreeParking /> },
  { key: 'Lockers', label: 'Lockers', icon: <Lockers /> },
  { key: 'PaidParking', label: 'Paid Parking', icon: <PaidParking /> },
  { key: 'Restaurant', label: 'Restaurant', icon: <Restaurant /> },
  { key: 'FloodLight', label: 'Flood Lights', icon: <FloodLight /> },
  { key: 'Showers', label: 'Showers', icon: <Showers /> },
  { key: 'WiFi', label: 'WiFi', icon: <WiFi /> },
  { key: 'FirstAidKit', label: 'First Aid Kit', icon: <FirstAidKit /> },
  { key: 'Bathroom', label: 'Bathroom', icon: <Bathroom /> },
  { key: 'EquipmentHiring', label: 'Equipment Hiring', icon: <EquipmentHiring /> },
];
export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      origin: null,
      bounds: null,
      readMore: [],
      isOpen: false,
      copySuccess: false,
      inputRef: React.createRef(),
      isFavourite: null,
      imageLoading: true,
      seeMore: false,
    };
    this.isFavouriteCalledRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.toggleFavouritesButton = this.toggleFavouritesButton.bind(this);
  }

  handleSubmit(values) {
    const { history, getListing, params, callSetInitialValues, onInitializeCardPaymentData } =
      this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const { publicData } = listing.attributes || {};
    const search = parse(this.props.location.search);
    const category = search.category;

    const isFacilityHireType = publicData?.activity.includes('facilityHire');

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const noOfAttendee = values?.attendee?.quantity;
    const noOfChildMaybe = values?.participants?.Children?.quantity || 0;
    const noOfInfantsMaybe = values?.participants?.Infants?.quantity || 0;
    const seats = parseInt(noOfAttendee);
    const protectedDataForTx = {
      category,
      attendee: noOfAttendee,
      ...(noOfChildMaybe ? { child: noOfChildMaybe } : {}),
      ...(noOfInfantsMaybe ? { infant: noOfInfantsMaybe } : {}),
    };
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);
    const bookingData = {
      seats,
      protectedData: protectedDataForTx,
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData: { ...bookingData, category, isFacilityHireType },
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  handleImageLoad = () => {
    // this.setState({ imageLoading: false });
    setTimeout(() => {
      this.setState({ imageLoading: false });
    }, 500);
  };

  handleImageError = () => {
    this.setState({ imageLoading: false });
  };

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then((txId) => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  isCurrentListingFavourite = async (userId, listingId) => {
    const currentUserFavourites = await axios.post(
      `${apiBaseUrl()}/api/is-listing-favourite`,
      {
        userId,
        listingId,
      },
      { withCredentials: true }
    );
    return currentUserFavourites;
  };
  toggleFavouritesButton = async (userId, listingId) => {
    const { onFetchCurrentUser } = this.props;
    let apiResp = false;
    const isFavouriteListing = await axios
      .post(
        `${apiBaseUrl()}/api/toggle-favourites`,
        {
          userId,
          listingId,
        },
        {
          withCredentials: true,
        }
      )
      .then((resp) => {
        apiResp = resp.data;
        return onFetchCurrentUser();
      })
      .then(() => {
        this.setState({ isFavourite: apiResp });
      });

    return isFavouriteListing;
  };
  componentDidMount() {
    this.isFavouriteCalledRef.current = false;
  }

  componentDidUpdate() {
    const search = parse(this.props.location.search);
    const { params, getListing, history, currentUser } = this.props;
    const listingId = new UUID(params.id);
    const listing = ensureListing(getListing(listingId));
    if (
      currentUser &&
      this.state.isFavourite === null &&
      this.isFavouriteCalledRef.current === false
    ) {
      this.isFavouriteCalledRef.current = true;
      this.isCurrentListingFavourite(currentUser.id.uuid, listingId.uuid).then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          isFavourite: res.data,
        }));
      });
    }

    if (!search?.category) {
      const currCategory = listing?.attributes?.publicData?.category?.[0];
      const currActivity = listing?.attributes?.publicData?.activity?.[0];
      if (
        currCategory &&
        currActivity === 'facilityHire' &&
        !this.props.location.search?.includes('category')
      ) {
        return history.replace(
          createResourceLocatorString('ListingPage', routeConfiguration(), params, {
            category: currCategory,
          })
        );
      }
    }
    if (!this.state.bounds && !this.state.origin && !!listing?.attributes?.geolocation) {
      this.setState({
        bounds: locationBounds(listing?.attributes?.geolocation, 1000),
        origin: listing?.attributes?.geolocation,
      });
    }
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      history,
      similarListings,
      similarListingsRequest,
      similarListingsError,
      viewport,
    } = this.props;
    const { favourites } = currentUser?.attributes?.profile?.privateData || [];
    const urlShr = typeof window !== 'undefined' ? window.location : null;
    const canonicalRootURL = config.canonicalRootURL;
    // const inputRef = useRef(null);
    const search = parse(location.search);
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;

    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    // const listingType = isDraftVariant
    //   ? LISTING_PAGE_PARAM_TYPE_DRAFT
    //   : LISTING_PAGE_PARAM_TYPE_EDIT;
    // const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;
    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    // const bnds = locationBounds(geolocation, 1000);

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );
    const images =
      publicData?.activity?.[0] === 'facilityHire'
        ? currentListing.images?.filter((i) =>
            publicData?.facilityImage?.[search?.category]?.includes(i.id.uuid)
          )
        : currentListing.images;
    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const listingType = publicData?.activity?.[0];

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              {<LoaderComponent className={css.loaderContainer} />}
              {/* <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p> */}
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = (e) => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const isSuperAdmin = currentUser?.attributes?.profile.publicData?.isSuperAdmin;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = (values) => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;

      if (isOwnListing || isCurrentlyClosed) {
        window.alert("can't book own listing");
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map((image) => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find((i) => i.name === variantName) : null;

          return variant || size;
        })
        .filter((variant) => variant != null);
    const duration = moment(publicData?.fromMongo?.scheduleDetails?.endTime, 'h:mm').diff(
      moment(publicData?.fromMongo?.scheduleDetails?.startTime, 'h:mm'),
      'hours'
    );

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map((img) => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.lrmain}>
            <div className={css.maindv}>
              <div className={css.headdv}>
                <p>
                  {publicData?.activity?.[0] === 'classes'
                    ? 'Fitness Class'
                    : publicData?.activity?.[0] === 'sessions'
                    ? 'Sports Session'
                    : publicData?.activity?.[0] === 'facilityHire'
                    ? 'Facility Hire'
                    : ''}
                </p>
                <div className={css.headmndv}>
                  <div>
                    <p>{title}</p>
                    <div className={css.secdvhead}>
                      {reviews && reviews.length > 0 ? (
                        <span className={css.reviewtopsec}>
                          <AiFillStar />
                          <span>4.7</span>
                          <span className={css.dot} />
                          <span>29 Reviews</span>
                        </span>
                      ) : null}{' '}
                      <span className={css.loctopsec}>
                        <div className={css.locatnWrap}>
                          <Pin />
                          <span
                            className={css.myvenue}
                            onClick={() => {
                              history.push(
                                createResourceLocatorString(
                                  'VenueDetail',
                                  routeConfiguration(),
                                  { id: publicData?.venueId },
                                  {}
                                )
                              );
                            }}
                          >
                            {publicData?.venueName}
                          </span>
                        </div>

                        <div className={css.VenueDtls}>
                          <span className={css.dot} />
                          {/* <span>{publicData?.venueAddress}</span> */}
                          {/* <span>{publicData?.fromMongo?.venueId?.venueDisplayAddress}</span> */}
                          <ExternalLink
                            href={`https://www.google.com/maps/search/?api=1&query=${geolocation?.lat},${geolocation?.lng}`}
                            target="_blank"
                            className={css.location}
                          >
                            <span>{publicData?.fromMongo?.venueId?.venueDisplayAddress}</span>
                          </ExternalLink>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className={css.shareContainer}
                    tabIndex={0}
                    onBlur={(e) => {
                      if (!e.currentTarget.contains(e.relatedTarget)) {
                        this.setState((p) => ({
                          isOpen: false,
                        }));
                      }
                    }}
                  >
                    <span
                      onClick={() =>
                        this.setState((p) => ({
                          isOpen: !p.isOpen,
                        }))
                      }
                    >
                      <Share className={css.shareIcon} />
                      <span>Share</span>
                    </span>

                    {this.state.isOpen && (
                      <div className={css.shareDiv}>
                        <div className={css.shareIconDiv}>
                          <FacebookShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId}`
                                : `${urlShr}`
                            }
                            // url='https://www.youtube.com/watch?v=9WzIACv_mxs'
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                          <WhatsappShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                            // url='https://www.youtube.com/watch?v=9WzIACv_mxs'
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <RedditShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                          >
                            <RedditIcon size={32} round />
                          </RedditShareButton>
                          <TwitterShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <TelegramShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                          >
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                          <EmailShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                          <LinkedinShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                          <LineShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                          >
                            <LineIcon size={32} round />
                          </LineShareButton>
                          <PinterestShareButton
                            url={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                          >
                            <PinterestIcon size={32} round />
                          </PinterestShareButton>
                        </div>
                        <div className={css.linkCopyShare}>
                          <input
                            type="text"
                            ref={this.state.inputRef}
                            value={
                              urlShr.pathname === `/venue/:${publicData?.venueId}`
                                ? ` ${canonicalRootURL}/venueDetail/${publicData?.venueId} `
                                : `${urlShr}`
                            }
                            readOnly
                            className={css.linkCopyBox}
                          />
                          <button
                            className={css.cpybtn}
                            onClick={(e) => {
                              this.state.inputRef.current.select();
                              document.execCommand('copy');
                              this.setState({ copySuccess: true });
                              const closeDiv = setTimeout(() => {
                                this.setState((p) => ({
                                  isOpen: !p.isOpen,
                                }));
                                this.setState({ copySuccess: false });
                              }, 2000);
                              return () => clearTimeout(closeDiv);
                            }}
                          >
                            {this.state.copySuccess ? ' Copied' : 'Copy URL'}
                          </button>
                        </div>
                      </div>
                    )}
                    <span>
                      {!isOwnListing && (
                        <button
                          className={css.favicon}
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();

                            if (!currentUser) {
                              return history.push({
                                pathname: '/login',
                                state: {
                                  from: `${location.pathname}${location.search}${location.hash}`,
                                },
                              });
                            }
                            !!currentUser &&
                              this.toggleFavouritesButton(currentUser.id.uuid, listingId.uuid);
                          }}
                        >
                          <HeartSvg
                            className={classNames(
                              { [css.unfavBtn]: this.state.isFavourite },
                              {
                                [css.favBtn]: !this.state.isFavourite,
                              }
                            )}
                          />
                        </button>
                      )}

                      {/* <AiFillHeart /> */}
                      {/* <span>Un favourite</span>{' '} */}
                    </span>
                    {isSuperAdmin && (
                      <span
                        className={css.favicon}
                        onClick={() =>
                          history.push(
                            createResourceLocatorString(
                              'SuperAdminDashboard',
                              routeConfiguration(),
                              { tab: 'listings' },
                              {
                                actionType: 'edit',
                                lTab: GENERAL_INFO,
                                listingType,
                                listing: rawParams.id,
                                // venueInfo: { ...listingVenue },
                              }
                            )
                          )
                        }
                      >
                        <EditSvg className={css.editIcon} />
                        <span>Edit</span>
                      </span>
                    )}
                  </div>
                  {/*  */}
                </div>
                {publicData?.activity?.[0] === 'facilityHire' &&
                  publicData?.category?.length >= 1 && (
                    <div className={css.catfilt}>
                      {publicData?.category?.map((c) => {
                        // const currCat = categoryOptions.find((ct) => ct.key === c);
                        return (
                          <button
                            className={classNames(css.ctflbtn, {
                              [css.actctbtn]: search?.category === c,
                            })}
                            type="button"
                            onClick={() =>
                              history.replace(
                                createResourceLocatorString(
                                  'ListingPage',
                                  routeConfiguration(),
                                  params,
                                  { category: c }
                                )
                              )
                            }
                          >
                            {/* <Football /> */}
                            <span>{c}</span>
                          </button>
                        );
                      })}
                    </div>
                  )}
              </div>
              <div className={css.bodydv}>
                <div className={css.detdv}>
                  <div
                    className={classNames(`${css.slimglispagsli} ${css.imgdiv}`, {
                      [css.oneimgcls]: images?.length === 1,
                    })}
                  >
                    <Slider {...settings} centerMode={viewport.width > 576 ? true : false}>
                      {images?.map((m) => (
                        <div
                          className={classNames(css.slckimgdv, {
                            [css.onePlusImg]: images.length > 1 && viewport.width > 576,
                          })}
                          key={m}
                        >
                          <img
                            className={this.state.imageLoading ? css.imageLoading : css.slckimg}
                            src={m?.attributes?.variants?.['scaled-medium']?.url}
                            onLoad={this.handleImageLoad}
                            onError={this.handleImageError}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className={css.infos}>
                    {/* <Football /> */}
                    <span className={css.info}>
                      {publicData?.activity?.[0] === 'facilityHire'
                        ? search?.category
                          ? publicData?.category?.find((c) => c === search?.category)
                          : publicData?.category?.[0]
                        : publicData?.category?.[0]}
                    </span>
                    {/* <span className={css.info}> */}

                    {publicData?.activity?.[0] === 'facilityHire' ? (
                      search?.category ? (
                        publicData?.fromMongo?.sportsInfo?.find(
                          (f) => f.sportName === search?.category
                        )?.subCategory ? (
                          <span className={css.info}>
                            {' '}
                            {
                              publicData?.fromMongo?.sportsInfo?.find(
                                (f) => f.sportName === search?.category
                              )?.subCategory
                            }{' '}
                          </span>
                        ) : null
                      ) : publicData?.subCategory?.[0] ? (
                        <span className={css.info}>{publicData?.subCategory?.[0]}</span>
                      ) : null
                    ) : publicData?.subCategory?.[0] ? (
                      <span className={css.info}>{publicData?.subCategory?.[0]}</span>
                    ) : null}
                    {/* </span> */}
                    {publicData?.activity?.[0] === 'facilityHire' ? (
                      <span className={css.info}>{publicData?.fromMongo?.facilityFormat}</span>
                    ) : null}
                    {publicData?.activity?.[0] === 'facilityHire' ? (
                      <span className={css.info}>{publicData?.fromMongo?.facilitySurface}</span>
                    ) : null}
                    {publicData?.activity?.[0] === 'facilityHire' ? null : (
                      <span className={css.info}>
                        {publicData?.difficulty === 'medium'
                          ? 'Intermediate Level'
                          : publicData?.difficulty === 'easy'
                          ? 'Beginner Level'
                          : publicData?.difficulty === 'hard'
                          ? 'Advanced Level'
                          : publicData?.difficulty === 'allLevels'
                          ? 'All Levels'
                          : ''}
                      </span>
                    )}

                    {publicData?.activity?.[0] === 'facilityHire' ? null : (
                      <span className={css.info}>
                        {publicData?.gender === 'male'
                          ? 'Men’s'
                          : publicData?.gender === 'female'
                          ? 'Women’s'
                          : publicData?.gender === 'mixed'
                          ? 'Mixed'
                          : ''}
                      </span>
                    )}
                    {duration ? (
                      publicData?.activity?.[0] === 'facilityHire' ? null : (
                        <span className={css.info}>
                          {duration}
                          {duration > 1 ? 'hours' : 'hour'}
                        </span>
                      )
                    ) : null}
                  </div>
                  {publicData?.activity?.[0] === 'facilityHire' ? null : (
                    <div className={css.authsec}>
                      <img src={publicData?.fromMongo?.agentId?.profilePicture?.url || ''} alt="" />
                      <div className={css.authdet}>
                        <div className={css.Namegrp}>
                          <p>
                            {publicData?.fromMongo?.agentId?.tag === 'referee'
                              ? 'Session Host'
                              : 'INSTRUCTOR'}
                          </p>
                          <p>
                            <span>
                              {`${publicData?.fromMongo?.agentId?.firstName}`}{' '}
                              {publicData?.fromMongo?.agentId?.lastName}
                            </span>
                          </p>
                        </div>
                        {/* <p>
                          <div className={css.reviewWrap}>
                            <span>
                              <AiFillStar />
                              5.0
                            </span>
                            <span className={css.dot} />
                            <span>4 Reviews</span>
                          </div>
                        </p> */}
                      </div>
                    </div>
                  )}
                  <div className={css.descdv}>
                    <p className={css.descriptionFont}>
                      {publicData?.activity?.[0] === 'facilityHire'
                        ? this.state.seeMore
                          ? publicData?.fromMongo?.sportsInfo?.find(
                              (f) => f.sportName === search?.category
                            )?.description
                          : publicData?.fromMongo?.sportsInfo
                              ?.find((f) => f.sportName === search?.category)
                              ?.description?.substring(0, 250)
                        : this.state.seeMore
                        ? publicData?.fromMongo?.listingDetails?.description
                        : publicData?.fromMongo?.listingDetails?.description?.substring(0, 250)}
                    </p>

                    {(publicData?.activity?.[0] === 'facilityHire' &&
                      publicData?.fromMongo?.sportsInfo?.find(
                        (f) => f.sportName === search?.category
                      )?.description?.length > 250 && (
                        <button
                          type="button"
                          onClick={(state) => {
                            this.setState({ seeMore: !this.state.seeMore });
                          }}
                        >
                          {!this.state.seeMore ? (
                            <>
                              <span>Show more</span>
                              <AiOutlineRight />
                            </>
                          ) : (
                            <>
                              <span>Show less</span>
                              <AiOutlineLeft />
                            </>
                          )}
                          {/* See more
                          <AiOutlineRight /> */}
                        </button>
                      )) ||
                      (publicData?.fromMongo?.listingDetails?.description?.length > 250 && (
                        <button
                          type="button"
                          onClick={(state) => {
                            this.setState({ seeMore: !this.state.seeMore });
                          }}
                        >
                          {!this.state.seeMore ? (
                            <>
                              <span>Show more</span>
                              <AiOutlineRight />
                            </>
                          ) : (
                            <>
                              <span>Show less</span>
                              <AiOutlineLeft />
                            </>
                          )}
                          {/* See more
                          <AiOutlineRight /> */}
                        </button>
                      ))}
                  </div>
                  <div className={css.amentiesdv}>
                    <label>Amenities</label>
                    <div>
                      {publicData?.activity?.[0] === 'facilityHire'
                        ? search?.category
                          ? publicData?.fromMongo?.sportsInfo
                              ?.find((f) => f.sportName === search?.category)
                              ?.aminities?.map((i) => {
                                const obj = amenities?.find((a) => a.key === i);
                                return (
                                  <div>
                                    {obj?.icon}
                                    <span>{obj?.label}</span>
                                  </div>
                                );
                              })
                          : publicData?.fromMongo?.listingDetails?.aminities?.map((i) => {
                              const obj = amenities?.find((a) => a.key === i);
                              return (
                                <div>
                                  {obj?.icon}
                                  <span>{obj?.label}</span>
                                </div>
                              );
                            })
                        : publicData?.fromMongo?.listingDetails?.aminities?.map((i) => {
                            const obj = amenities?.find((a) => a.key === i);
                            return (
                              <div>
                                {obj?.icon}
                                <span>{obj?.label}</span>
                              </div>
                            );
                          })}
                    </div>
                  </div>

                  {publicData?.activity?.[0] === 'facilityHire'
                    ? Object.keys(
                        publicData?.fromMongo?.sportsInfo?.find(
                          (f) => f.sportName === search?.category
                        )?.editorData || {}
                      ).map((info) => {
                        const content = publicData?.fromMongo?.sportsInfo?.find(
                          (f) => f.sportName === search?.category
                        )?.editorData?.[info];
                        return (
                          <div className={css.rulesdv} key={info}>
                            <label>
                              {info === 'rules'
                                ? 'Rules'
                                : info === 'whatToBring'
                                ? 'What To Bring'
                                : info === 'whatIsProvided'
                                ? 'What Is Provided'
                                : info === 'thingsToKnow'
                                ? 'Things To Know'
                                : info === 'whatYouDo'
                                ? 'what you’ll do'
                                : ''}
                            </label>
                            <div
                              className={classNames(css.dshd, {
                                [css.seeFull]: this.state.readMore?.includes(info),
                              })}
                              dangerouslySetInnerHTML={{
                                __html: this.state.readMore?.includes(info)
                                  ? content
                                  : content?.substring(0, 200),
                              }}
                            />
                            {content?.length > 200 && (
                              <button
                                type="button"
                                onClick={() => {
                                  this.setState((state) => ({
                                    readMore: state.readMore?.includes(info)
                                      ? state.readMore?.filter((r) => r !== info)
                                      : [...state.readMore, info],
                                  }));
                                }}
                              >
                                {!this.state.readMore.includes(info) ? (
                                  <>
                                    <span>Show more</span>
                                    <AiOutlineRight />
                                  </>
                                ) : (
                                  <>
                                    <span>Show less</span>
                                    <AiOutlineLeft />
                                  </>
                                )}
                              </button>
                            )}
                          </div>
                        );
                      })
                    : Object.keys(
                        publicData?.fromMongo?.listingDetails?.extraInformation || {}
                      ).map((info) => {
                        const content =
                          publicData?.fromMongo?.listingDetails?.extraInformation?.[info];
                        return (
                          <div className={css.rulesdv} key={info}>
                            <label>
                              {info === 'rules'
                                ? 'Rules'
                                : info === 'whatToBring'
                                ? 'What To Bring'
                                : info === 'whatIsProvided'
                                ? 'What Is Provided'
                                : info === 'thingsToKnow'
                                ? 'Things To Know'
                                : info === 'whatYouDo'
                                ? 'what you’ll do'
                                : ''}
                            </label>
                            <div
                              className={classNames(css.dshd, {
                                [css.seeFull]: this.state.readMore?.includes(info),
                              })}
                              dangerouslySetInnerHTML={{
                                __html: this.state.readMore?.includes(info)
                                  ? content
                                  : content?.substring(0, 200),
                              }}
                            />

                            {content?.length > 200 && (
                              <button
                                type="button"
                                onClick={() => {
                                  this.setState((state) => ({
                                    readMore: !!state.readMore.includes(info)
                                      ? state.readMore?.filter((r) => r !== info)
                                      : [...state.readMore, info],
                                  }));
                                }}
                              >
                                {!this.state.readMore.includes(info) ? (
                                  <>
                                    <span>Show more</span>
                                    <AiOutlineRight />
                                  </>
                                ) : (
                                  <>
                                    <span>Show less</span>
                                    <AiOutlineLeft />
                                  </>
                                )}
                              </button>
                            )}
                          </div>
                        );
                      })}

                  {currentListing && this.state.bounds && this.state.origin ? (
                    <div className={css.locationdv}>
                      <label>Location</label>
                      {/* <div className={css.address}>
                        <ExternalLink
                          href={`https://www.google.com/maps/search/?api=1&query=${geolocation?.lat},${geolocation?.lng}`}
                          target="_blank"
                          className={css.location}
                        >
                          <span className={css.addressStyle}>
                            {publicData?.fromMongo?.venueId?.venueDisplayAddress}
                          </span>
                        </ExternalLink>
                      </div> */}

                      <span className={css.loctopsec}>
                        {/* <div className={css.locatnWrap}>
                          <Pin />
                          <span
                            className={css.myvenue}
                            onClick={() => {
                              history.push(
                                createResourceLocatorString(
                                  'VenueDetail',
                                  routeConfiguration(),
                                  { id: publicData?.venueId },
                                  {}
                                )
                              );
                            }}
                          >
                            {publicData?.venueName}
                          </span>
                        </div> */}

                        <div className={css.VenueDtls}>
                          {/* <span className={css.dot} /> */}
                          <ExternalLink
                            href={`https://www.google.com/maps/search/?api=1&query=${geolocation?.lat},${geolocation?.lng}`}
                            target="_blank"
                            className={css.mapLoc}
                          >
                            <Pin />
                            <span
                              className={css.maplocVenue}
                            >{`${publicData?.venueName} ${publicData?.fromMongo?.venueId?.venueDisplayAddress}`}</span>
                          </ExternalLink>
                        </div>
                      </span>

                      {/* <SearchMap
                        reusableContainerClassName={css.map}
                        // activeListingId={activeListingId}
                        bounds={this.state.bounds}
                        center={this.state.origin}
                        location={location}
                        listings={[currentListing] || []}
                        onMapMoveEnd={() => {}}
                        messages={intl.messages}
                      /> */}
                      <SectionMapMaybe
                        geolocation={geolocation}
                        publicData={publicData}
                        listingId={currentListing.id}
                      />
                    </div>
                  ) : null}
                  {reviews && reviews.length > 0 ? (
                    <div className={css.rvwdv}>
                      <label>Reviews(2)</label>
                      <div>
                        <div className={css.echrvw}>
                          <div>
                            <img
                              src="https://sharetribe.imgix.net/636a7ee5-fa00-432a-85b0-f843c9d72ca3/642ffabd-f5f1-4099-9ba3-f18c25bf08d0?auto=format&fit=clip&h=750&w=750&s=2989d81bc2f942e2022429f80293e962"
                              alt=""
                            />
                          </div>
                          <div className={css.rvwinfo}>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa
                              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla,
                              mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis
                              tellus.
                            </p>
                            <div>
                              <span>Juho Makkonen</span>
                              <span className={css.dot} />
                              <span>February 2023</span>
                              <span className={css.dot} />
                              <span>
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={css.echrvw}>
                          <div>
                            <img
                              src="https://sharetribe.imgix.net/636a7ee5-fa00-432a-85b0-f843c9d72ca3/642ffabd-f5f1-4099-9ba3-f18c25bf08d0?auto=format&fit=clip&h=750&w=750&s=2989d81bc2f942e2022429f80293e962"
                              alt=""
                            />
                          </div>
                          <div className={css.rvwinfo}>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa
                              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla,
                              mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis
                              tellus.
                            </p>
                            <div>
                              <span>Dibya Mondal</span>
                              <span className={css.dot} />
                              <span>January 2022</span>
                              <span className={css.dot} />
                              <span>
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiOutlineStar />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={css.echrvw}>
                          <div>
                            <img
                              src="https://sharetribe.imgix.net/636a7ee5-fa00-432a-85b0-f843c9d72ca3/642ffabd-f5f1-4099-9ba3-f18c25bf08d0?auto=format&fit=clip&h=750&w=750&s=2989d81bc2f942e2022429f80293e962"
                              alt=""
                            />
                          </div>
                          <div className={css.rvwinfo}>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa
                              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla,
                              mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis
                              tellus.
                            </p>
                            <div>
                              <span>Juho Makkonen</span>
                              <span className={css.dot} />
                              <span>February 2023</span>
                              <span className={css.dot} />
                              <span>
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiOutlineStar />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="button">
                        See all Reviews
                        <AiOutlineRight />
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className={css.bookpaneldv}>
                  <BookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    title={bookingTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    searchParams={search}
                  />
                </div>
              </div>
              {/* <div className={css.similardv}>
                {similarListingsRequest ? (
                  <IconSpinner />
                ) : similarListingsError ? (
                  <span>Failed to fetch similar listing</span>
                ) : (
                  <>
                    <label>Similar</label>
                    <div className={css.listingsContainer}>
                      {similarListings?.length > 0 &&
                        similarListings.map((l) => (
                          <ListingCard
                            className={css.listingCard}
                            key={l.id.uuid}
                            listing={l}
                            renderSizes={cardRenderSizes}
                            // setActiveListing={setActiveListing}
                            history={history}
                            currentUser={currentUser}
                            toggleFavouritesButton={this.toggleFavouritesButton}
                            isFavourite={_.isArray(favourites) && favourites?.includes(l.id.uuid)}
                            location={location}
                            // urlQueryParams={{ ...search, pub_category: null }}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div> */}
            </div>

            {/* <div> */}
            {/* <SectionImages
              title={title}
              listing={currentListing}
              isOwnListing={isOwnListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
              }}
              imageCarouselOpen={this.state.imageCarouselOpen}
              onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
              handleViewPhotosClick={handleViewPhotosClick}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
            {/* <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />
                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    listingCertificate={publicData ? publicData.certificate : null}
                    certificateOptions={certificateOptions}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                  />
                  <SectionDescriptionMaybe description={description} />
                  <SectionFeaturesMaybe options={yogaStylesOptions} publicData={publicData} />
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              </div>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal> */}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    similarListingsIds,
    similarListingsRequest,
    similarListingsError,
  } = state.ListingPage;
  const { venues } = state.VenueDetail;
  const { currentUser } = state.user;
  const similarListings =
    similarListingsIds?.length > 0 && getListingsById(state, similarListingsIds);
  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = (id) => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    similarListings,
    similarListingsRequest,
    similarListingsError,
    venues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchCurrentUser: () => dispatch(fetchCurrentUser()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(ListingPageComponent);

export default ListingPage;
